/**
 * List of possible types:
 * https://surveyjs.io/form-library/documentation/api-reference/question#getType
 */
export const allowedSurveyJSQuestionTypes = [
  'boolean',
  'checkbox',
  'comment',
  'dropdown',
  'tagbox',
  // "expression",
  // "file",
  // "html",
  // "image",
  // "imagepicker",
  'matrix',
  'matrixdropdown',
  'matrixdynamic',
  'multipletext',
  'panel',
  // "paneldynamic",
  'radiogroup',
  // "rating",
  // "ranking",
  // "signaturepad",
  'text',
];
