<ng-container *transloco="let t; read: 'form-table-editor'">
  <p-messages severity="warn">
    <ng-template pTemplate>
      {{ t('betaWarning') }}
    </ng-template>
  </p-messages>

  <app-data-table
    [actionsTemplate]="actions"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [selectedCols]="cols"
    [tableRows]="tableRows$"
    [translationScope]="'form-table-editor'"
  >
    <ng-container appDataTableColumnDef="edit">
      <th *appDataTableHeader>{{ t('colEdit') }}</th>
      <td *appDataTableCell="let rowData">
        <button
          class="p-button-rounded p-button-primary p-button-text"
          pButton
          type="button"
          (click)="onEditFormClicked(rowData)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-template #actions>
      <p-button
        icon="pi pi-plus"
        styleClass="p-button-outlined"
        [label]="t('createFormButtonLabel')"
        (onClick)="openNewFormDialog()"
      ></p-button>
    </ng-template>
  </app-data-table>
</ng-container>
