import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { map } from 'rxjs';

import { makePTableCols } from '../../../common/p-table/make-p-table-cols';
import { DataTableModule } from '../../generic/data-table/data-table.module';
import { SettingsModule } from '../../generic/settings/settings.module';
import { FormEditorComponent } from '../form-editor/form-editor.component';
import { FormService } from '../services/form.service';

import { formEditorDialogConfig } from './form-editor-dialog-config';
import { createFormEditorRow } from './helpers/create-form-editor-row/create-form-editor-row';
import { FormEditorRow } from './helpers/create-form-editor-row/interfaces';

@Component({
  selector: 'app-form-table-editor',
  standalone: true,
  templateUrl: './form-table-editor.component.html',
  styleUrls: ['./form-table-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonModule,
    CommonModule,
    DataTableModule,
    DynamicDialogModule,
    FormEditorComponent,
    MessagesModule,
    SettingsModule,
    SharedModule,
    TranslocoModule,
    DropdownModule,
    MatIconModule,
  ],
  providers: [DialogService],
})
export class FormTableEditorComponent {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Services
  //////////////////////////////////////////////////////////////////////////////////////////

  private readonly dialogService = inject(DialogService);

  private readonly formService = inject(FormService);

  //////////////////////////////////////////////////////////////////////////////////////////
  // Data
  //////////////////////////////////////////////////////////////////////////////////////////

  public readonly cols = makePTableCols({
    columns: ['displayName', 'description', 'edit'],
    translationScope: 'form-table-editor',
  });

  public readonly tableRows$ = this.formService
    .getDocsArray({ takeOne: false })
    .pipe(map((forms) => forms.map((form) => createFormEditorRow(form))));

  public openNewFormDialog(): void {
    this.dialogService.open(FormEditorComponent, {
      ...formEditorDialogConfig,
      data: {
        formId: this.formService.createId(),
      },
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Edit Form
  //////////////////////////////////////////////////////////////////////////////////////////

  protected onEditFormClicked(rowData: FormEditorRow) {
    this.dialogService.open(FormEditorComponent, {
      ...formEditorDialogConfig,
      data: {
        formId: rowData.id,
      },
    });
  }
}
