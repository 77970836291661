/* eslint-disable */
export const environment = {
  production: false,
  useEmulators: false,
  webappBaseURL: 'https://pwp.helplinesoftware.com',
  webchatBaseURL: 'https://webchat.helplinesoftware.com',
  firebase: {
    apiKey: 'AIzaSyBidjapTokblGWAx1-AarHEdVYt0mQ2PN8',
    authDomain: 'pwp-prod-10uj.firebaseapp.com',
    databaseURL: 'https://pwp-prod-10uj.firebaseio.com',
    projectId: 'pwp-prod-10uj',
    storageBucket: 'pwp-prod-10uj.appspot.com',
    messagingSenderId: '961727257782',
    appId: '1:961727257782:web:b8088d4d6c537665a2da17',
  },
  fullCalendar: {
    licenseKey: '0415253183-fcs-1727959603',
  },
  surveyJS: {
    licenseKey: 'ZWYxZDNhM2EtZDZkMS00MTRkLTg1NDktZWRjNGQ1ZjFmZWY3OzE9MjAyNS0xMS0yMywyPTIwMjUtMTEtMjMsND0yMDI1LTExLTIz',
  },
};

export const projectNumber = 961727257782;
////////////////////////////////////////////////////////////////////////////////////////////
// Constants
////////////////////////////////////////////////////////////////////////////////////////////

const region = 'us-central1';
export const latestGitCommitHash = '07b9438';

////////////////////////////////////////////////////////////////////////////////////////////
// Env Specific Constants
////////////////////////////////////////////////////////////////////////////////////////////

// URL where functions are deployed
// The placeholder constant below is replaced with the actual hostname in a script.

export const host = `${region}-${environment.firebase.projectId}.cloudfunctions.net`;
export const baseURL = `https://${host}`;
